<template>
    <div>
      <h2>Admin Dashboard Calendar</h2>
      <v-layout wrap justify-center>
        <v-flex lg4 v-for="(month, index) in months" :key="index">
        <v-card  @click="openDialog(index)">
        <v-layout wrap>
            <v-flex lg12>
          <v-card-title>{{ month }}</v-card-title>
          <v-dialog v-model="dialog" persistent>
            
            <v-card>
              <v-card-title>{{ months[dialogIndex] }}</v-card-title>
              <v-card-text>
                <div class="days">
                  <div class="day" v-for="day in daysInMonth(dialogIndex)" :key="day">{{ day }}</div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" text @click="closeDialog">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-flex>
        </v-layout>
        </v-card>
        </v-flex>
      </v-layout>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        dialog: false,
        dialogIndex: null
      };
    },

    methods: {
      daysInMonth(monthIndex) {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = monthIndex;
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        return Array.from({ length: daysInMonth }, (_, i) => i + 1);
      },
      openDialog(index) {
        this.dialogIndex = index;
        this.dialog = true;
      },
      closeDialog() {
        this.dialog = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .admin-calendar {
    /* Add styles as needed */
  }
  .calendar {
    display: flex;
    flex-wrap: wrap;
  }
 
  .days {
    display: flex;
    flex-wrap: wrap;
  }
  .day {
    width: calc(100% / 7); /* Adjust as needed */
    text-align: center;
    padding: 5px;
  }
  </style>
  